/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
import itsbeen84years from "src/images/BackToGameDev/itsbeen84years.webp";
import inserters from "src/images/BackToGameDev/inserters.webp";
import bugs from "src/images/BackToGameDev/bugs.webp";
import BlogPage from "../../../Components/BlogPage";
import ExternalLink from "../../../Components/ExternalLink";
import ResponsibleImage from "../../../Components/ResponsibleImage";
import Paragraph from "../../../Components/Typography/Paragraph";
import {BackToGameDevRoute} from "../../../Routes/MenuRoutes";
import MKTypography from "../../../material-kit/MKTypography";
import Bullets from "../../../Components/Typography/Bullets";

export default function BackToGameDev() {
    return (
        <BlogPage
            title="Coming back to gamedev after a break"
            description="What makes it easy to pick up suspended projects"
            thumbnail={itsbeen84years}
            route={BackToGameDevRoute}
            date="2025-01-07"
        >
            <Paragraph>
                <ResponsibleImage src={itsbeen84years} alt="inserters" para/>
                Recently I took 6 months off from game development. It was a lot of good stuff: vacations, 3D printing
                projects for home improvement, playing Factorio 2.0 Space Age. Factorio is now absolutely my favorite
                game of all time and I had quite a few dreams featuring belts and inserters.
                <ResponsibleImage src={inserters} alt="inserters" para/>
            </Paragraph>
            <Paragraph>
                But at some point in December, I snapped back from it, updated Unity and Rider and got back to
                coding.
            </Paragraph>
            <Paragraph>
                Over this time, I had several new game ideas and a few improvements to old ideas. I thought I’d
                naturally start something new and abandon the last game I worked on, Fracturix. But it would’ve been a
                shame, as I put a decent amount of work into it and I felt it had only started to unfold from a tech
                demo into a game prototype. Coding is my strength, and while I’m proud of some proc gen stuff in the
                game, the rest of it was almost too easy. It’s a brick breaker game after all, not rocket science.
                Anyway, there was a large sunk-cost fallacy situation, so I decided to try to fix a few bugs and at
                least push out a demo so it wouldn’t all be in vain.
                <ResponsibleImage src={bugs} alt="bugs" para/>
            </Paragraph>
            <Paragraph>
                How big was my surprise when I realized that I could totally pick up from where I was! I fixed bugs,
                added a few missing features and juice here and there, and it was not a drag at all. I suppose good
                coding practices really help in situations like this. Here are the things I believe helped me write code
                that can be picked up seamlessly after a half-year break:
            </Paragraph>
            <Paragraph>
                <Bullets>
                    <li>
                        Make code speak for itself. It shouldn’t need comments to understand what’s going on. Everything
                        needs to be broken into self-sufficient pieces: modules, functions that have one purpose and a
                        name that clearly explains it. If a function grows too big, don’t break it up randomly just
                        because it is, refactor it in a way that produces nice reusable modules with obvious
                        responsibilities.
                    </li>
                    <li>
                        If something weird has to happen, add a comment explaining what is going on and why. For
                        example, for some reason Unity wants me to initialize procedural materials no earlier than the
                        second frame. I didn’t waste too much time investigating why that is, but added a comment
                        explaining it.

                    </li>
                    <li>
                        Put some thought into software architecture. It’s nearly always worth spending a few minutes
                        thinking and doodling before writing code, rather than spending hours later refactoring bad
                        smelly spaghetti.
                    </li>
                    <li>
                        Don’t over-engineer. To balance the previous point, it’s important to stay focused on the goal
                        rather than architecture. It’s better to come back later and refactor a thing (if needed),
                        rather than create a universal omnipotent tool that is only used once and is a pain in the arse
                        to maintain.
                    </li>
                    <li>
                        Use good libraries. For example, I really love <ExternalLink
                        href="https://github.com/KyryloKuzyk/PrimeTween">Primetween</ExternalLink>. It’s very smart,
                        fast and well-thought-out.
                    </li>
                    <li>
                        Maintain a good todo list. I got all my ideas, plans and bugs in one hierarchical list and I use
                        it as an external memory for myself. I always keep it sorted by priority. Every time I come back
                        to the project, no matter if a day or six months have passed, I 100% know what is the next thing
                        I need to work on. Use your favorite tool here. My favorite is <ExternalLink
                        href="https://workflowy.com/invite/865a1ec0.lnx">Workflowy</ExternalLink>. It’s an infinite
                        hierarchical bullet point list, where you can zoom in to any level or fold out details that’s
                        irrelevant today. It really helps me to keep all ideas and notes in a way that’s not cluttered
                        but always available to any depth I need.
                    </li>
                </Bullets>
            </Paragraph>
        </BlogPage>
    );
}
