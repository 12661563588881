/* eslint-disable react/no-unescaped-entities */
import {
    Circle,
    Email,
    GitHub,
    Instagram,
    Twitter,
    YouTube
} from "@mui/icons-material";
import OpenGraph from "../Components/OpenGraph";
import Social from "../Components/Social";
import Paragraph from "../Components/Typography/Paragraph";
import MKBox from "../material-kit/MKBox";
import MKTypography from "../material-kit/MKTypography";
// eslint-disable-next-line import/no-unresolved
import logoUrl from "src/images/ombosoft.png";
import { AboutRoute } from "../Routes/MenuRoutes";

export const emailAddress = "ombosoft@gmail.com";

export function Contacts() {
    return (
        <>
            {" "}
            <Social
                icon={<Email />}
                network="e-mail"
                handle={emailAddress}
            />
            <Social icon={<Circle />} network="Discord" handle="ombosoft" />
            <Social
                icon={<Circle />}
                network="Threads"
                link="https://www.threads.net/@ombosoft/"
                handle="@ombosoft"
            />
            <Social
                icon={<Twitter />}
                network="X"
                link="https://twitter.com/ombosoft"
                handle="@ombosoft"
            />
        </>
    );
}

export function AboutPage() {
    return (
        <>
            <OpenGraph
                title="About Ombosoft"
                description="Indie game developer"
                thumbnail={logoUrl}
                route={AboutRoute}
            />
            <MKBox>
                <MKTypography variant="h1" color="info" textGradient>
                    Shurick
                </MKTypography>
                <Paragraph>
                    Hi, I'm Shurick. Software engineer by day, solo indie game developer by night.
                </Paragraph>
                <MKTypography variant="h1" color="info" textGradient pt={2}>
                    Ombosoft
                </MKTypography>
                <Paragraph>
                    Ombosoft is my hobby project. My goal is to build fun games
                    and useful tools.
                </Paragraph>
                <MKTypography variant="h2" color="info" textGradient pt={2}>
                    Contacts
                </MKTypography>
                <MKTypography variant="body2" component="div">
                    <Contacts />
                    <Social
                        icon={<GitHub />}
                        network="GitHub"
                        link="https://github.com/Ombosoft"
                        handle="Ombosoft"
                    />
                    {/* <Social
                        icon={<Reddit />}
                        network="Reddit"
                        link="https://www.reddit.com/r/Ombosoft/"
                        handle="r/Ombosoft"
                    /> */}
                    <Social
                        icon={<Instagram />}
                        network="Instagram"
                        link="https://www.instagram.com/ombosoft/"
                        handle="ombosoft"
                    />
                    <Social
                        icon={<YouTube />}
                        network="YouTube"
                        link="https://www.youtube.com/@ombosoft"
                        handle="@ombosoft"
                    />
                    <Social
                        icon={<Circle />}
                        network="TikTok"
                        link="https://www.tiktok.com/@ombosoft"
                        handle="@ombosoft"
                    />
                    {/* <Social
                        icon={<Facebook />}
                        network="Facebook"
                        link="https://www.facebook.com/profile.php?id=100072264743890"
                        handle="Ombosoft"
                    /> */}
                </MKTypography>
            </MKBox>
        </>
    );
}
