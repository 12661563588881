/* eslint-disable react/no-unescaped-entities */
import {Stack} from "@mui/material";
import InternalLink from "../Components/InternalLink";
import OpenGraph from "../Components/OpenGraph";
import Paragraph from "../Components/Typography/Paragraph";
import {AboutRoute, PaintMatchRoute} from "../Routes/MenuRoutes";
import MKTypography from "../material-kit/MKTypography";
import {Contacts} from "./AboutPage";
// eslint-disable-next-line import/no-unresolved
import logoUrl from "src/images/ombosoft.png";
import PageBox from "../Components/PageBox";

export default function HomePage() {
    const latestGame = PaintMatchRoute;
    const LatestGameComponent = latestGame.component.type;
    return (
        <>
            <OpenGraph
                title="Ombosoft"
                description="Indie game developer"
                thumbnail={logoUrl}
            />
            <PageBox>
                <MKTypography
                    variant="h1"
                    gutterBottom
                    color="info"
                    textGradient
                >
                    Crafting games
                </MKTypography>
                <MKTypography variant="h3" gutterBottom>
                    By night
                </MKTypography>
                <Paragraph>
                    Hi, I'm Shurick — software engineer by day 🌞,
                    solo indie game developer by night 🌜.
                    I remake some of my favorite classic games 🕹 with my own twists, and create original ones.
                    My solo game studio is called Ombosoft.
                    Here, I share the games and apps I've crafted, along with
                    insights from my development journey.
                </Paragraph>
                <Paragraph gutterBottom>
                    Please check out my best original game so far:
                </Paragraph>
                <LatestGameComponent headerOnly route={latestGame.route}/>
                <Paragraph>
                    Contact me:
                    <Contacts/>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {AboutRoute.icon}
                        <InternalLink to={AboutRoute.route} text="And more"/>
                    </Stack>
                </Paragraph>
            </PageBox>
        </>
    );
}
